export const Path = {
    homescreen : "/home",
    login:"/",
    product:"/product",
    productdetails:"/product/:id",
    about:"/about",
    contact:"/contact",
    checkout:"/checkout",
    Orderdetails:"/orderdetails"
        
}